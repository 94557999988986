<template>
  <div>
    <load-profile></load-profile>

    <b-skeleton-wrapper :loading="getLoading">
      <template #loading>
        <b-card>
          <b-skeleton width="200px"></b-skeleton>
          <b-skeleton width="100px"></b-skeleton>
          <b-row class="mt-4">
            <b-col
              class="mb-2"
              cols="12"
              md="6"
              v-for="i in 8"
              :key="`loading-${i}`"
            >
              <b-skeleton width="200px"></b-skeleton>
              <b-skeleton type="input"></b-skeleton>
            </b-col>
          </b-row>
        </b-card>
      </template>
      <b-card>
        <h3>{{ $t(item.name) }}</h3>
        <small class="mb-2 d-block">{{ item.description }}</small>
        <validation-observer ref="editForm" #default="{ invalid }">
          <n-form-confirmation
            key="editForm"
            @submit="submit"
            :form="$refs.editForm"
            :disabled="invalid"
          >
            <n-input
              :key="`input-${key}`"
              :fields="fields"
              v-model="data"
            ></n-input>
            <b-row>
              <b-col cols="12" class="text-right">
                <n-button-save-option
                  ref="btnSubmit"
                  @submit="submit"
                  @save="save"
                  :loading="loading"
                  :resource="resource"
                  :route="route"
                  :hide-create="true"
                  v-if="$can('update', resource)"
                ></n-button-save-option>
                <n-button-loading
                  type="button"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  :loading="loading"
                  class="mt-1"
                  @submit="back"
                >
                  {{ $t("button.back") }}
                </n-button-loading>
              </b-col>
            </b-row>
          </n-form-confirmation>
        </validation-observer>
      </b-card>
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BSkeletonWrapper,
  BSkeleton,
  BSkeletonTable,
} from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import Ripple from "vue-ripple-directive";
import NButtonLoading from "@/components/NButtonLoading";
import NFormConfirmation from "@/components/NFormConfirmation";
import NInput from "@/components/NInput";
import NButtonSaveOption from "@/components/NButtonSaveOption";

const ConfigurationRepository = Repository.get("configuration");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BSkeletonWrapper,
    BSkeleton,
    BSkeletonTable,

    NButtonLoading,
    NFormConfirmation,
    NInput,
    NButtonSaveOption,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      getLoading: true,
      key: 1,
      item: {},
      data: {},
      loading: false,
      fields: [],
    };
  },
  mounted() {
    this.show();
  },
  methods: {
    show() {
      this.getLoading = true;
      ConfigurationRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.item = { ...data };
            this.fields = [...data.configs];
            let value = {};
            this.fields.forEach((element) => {
              value[element.key] = element.value;
            });

            this.data = {
              ...value,
            };
            this.key++;
          }
          this.getLoading = false;
        })
        .catch(() => (this.getLoading = false));
    },
    submit(type) {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.$refs.btnSubmit.confirm(type);
        }
      });
    },
    save(type) {
      this.loading = true;
      let configs = [];
      this.fields.forEach((element) => {
        configs.push({
          key: element.key,
          value: this.data[element.key],
        });
      });
      const data = {
        id: this.data.id,
        configs: configs,
      };
      ConfigurationRepository.update(this.$route.params.id, data)
        .then((response) => {
          this.$refs.btnSubmit.afterSave(type, response.data.data.id);
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.editForm.setErrors(error.response?.data?.message);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    back() {
      this.$router.push({
        name: `list-${this.route}`,
      });
    },
  },
  setup() {
    const resource = "configuration";
    const route = "configuration";

    return { resource, route };
  },
};
</script>